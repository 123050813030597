@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import './_TopNav', './_BottomNav', './_MainPage', './_Player', './TinderCardContainer', './_MainCard', './_LoginPage', './_PickGenrePage', './_YouPage';


$swipoGreen: #10D48E;
$pureWhite: #FFFFFF;
$richBlack: #0E0F0E;
$altBlack: #161616;
$smokeBlack: #434F4A;
$borderFaint: #DDDDDD;

//new colors
$offWhite: rgba(240, 240, 240, 1);


body {
    // font
    color: black;
    font-family: "Inter", sans-serif;

    margin: 0;
    padding: 0;

    // disallows scroll
    overflow-x:hidden;
    overflow-y:hidden;
}

.App {
    // sets background
    background-color: $offWhite;
    height: 100vh;
    width: 100vw;
}

.borderFaint {
    border: 1px solid $borderFaint;
}