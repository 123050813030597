$swipoGreen: #10D48E;
$pureWhite: #FFFFFF;
$richBlack: #0E0F0E;
$altBlack: #161616;
$offWhite: #F0F0F0;

 .bottom-nav {
    background-color: $pureWhite;
    // border: 3px solid blue;
    // position: fixed;
    // bottom: 20px;
    width: 30vw;
    min-width: 300px;
    min-height: 70px;
    margin-bottom: 15px;
    border-radius: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 0;
    @media (max-width: 425px) {
        margin-bottom: 10px;
    }
    #playlistIcon {
        width: 25px;
        margin-top: 5px;
        // border: 1px solid green;
    }
    #homeIcon {
        height: 30px;
    }
    #youIcon {
        width: 25px;
        margin-top: 3px;
        // border: 1px solid green;
    }
}