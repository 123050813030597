.tinderCardContainer {
    // border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    flex-direction: column;
    margin-top: 100px;
    width: 100%;
    height: 100%;
}