$swipoGreen: #10D48E;
$pureWhite: #FFFFFF;
$richBlack: #0E0F0E;
$altBlack: rgba(22,22,22,1);
$smokeBlack: #434F4A;

$rhap_theme-color: $pureWhite;   // 
$rhap_background-color: $altBlack; // 
$rhap_bar-color: #FFFFFF;
$rhap_time-color: #FFFFFF;
$rhap_font-family: 'Inter', sans-serif;

$rhap_theme-color: #868686 !default;
$rhap_background-color: #fff !default;
$rhap_bar-color: #dddddd !default;
$rhap_time-color: #333 !default;
$rhap_font-family: inherit !default;

.tinderCard {
  display: flex;
  position: absolute;
  will-change: transform;
  transition: all 0.3s linear;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
  // width: fit-content;
  &:hover {
    cursor: pointer;
  }

  &.moving {
    transition: none;
  }
  .mainCard{
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: center;
    // border: 1px solid black;
    border-radius: 30px;
    width: 275px;
    // max-height: 335px;
    @media (max-width: 475px) {
      max-width: 75vw;
    }
    @media (max-height: 600px) {
      max-width: 60vw;
    }
    // padding: 20px;
    background-color: white;
    
    #albumCover{
      width: 90%;
      aspect-ratio: 1/1;
      align-items: center;
      justify-content: center;
      margin-top: 25px;
      // border-radius: 20px;
      pointer-events: none;
    }
    .songName{
      // border: 1px solid black;
      margin: 10px 20px 0px 20px;
      padding: 0;
      color: $altBlack;
      max-width: 90%;
      pointer-events: none;
      h1 {
        font-weight: 600;
        font-size: 24px;
        margin: 0;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
        // border: 1px solid black;
      }
    }
    .artist{
      text-align: center;
      margin-bottom: 10px;
      // border: 1px solid black;
      max-width: 90%;
      pointer-events: none;
      h2 {
        font-weight: 300;
        font-size: 18px;
        color: #16161691;
        margin: 5px 0px 0px 0px;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
      }
    }
    #audio{
      //delete once all custom audio control buttons are created.
      width: 100%;
      height: 80%;
      top: 0;
    }
  }
  #controlsBox {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-top: 20px;
    // border: 1px solid $borderFaint;
    width: 100%;
    .invisible {
      visibility: hidden;
      width: 50px;
      height: 50px;
      border: 1px solid yellow;
    }
    .playPauseButton {
      width: 110px;
      height: 40px;
      background-color: white;
      border-radius: 20px;
      margin-bottom: 10px;
      color: $smokeBlack;
      font-weight: 600;
      font-size: 15px;
      &:hover {
        background-color: rgba($swipoGreen, 0.7);
        color: rgba(white, 0.7);
      }
    }
    .openSpotifyButton{
      // margin-right: 20px;
      margin-top: -7px;
      border: none;
      background-color: transparent;
      img {
        width: 40px;
        height: 40px;
        opacity: 0.8;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

//once all important audio control buttons have been custom coded,
//remove all of this.
.rhap_container {
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  line-height: 1;
  font-family: $rhap_font-family;
  width: 100%;
  padding: 10px 15px;
  background-color: $rhap_background-color;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  svg {
    vertical-align: initial; // overwrite Bootstrap default
  }
}

.rhap_header {
  margin-bottom: 10px;
}

.rhap_footer {
  margin-top: 5px;
}

.rhap_main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.rhap_stacked {
  .rhap_controls-section {
    margin-top: 8px;
  }
}

.rhap_horizontal {
  flex-direction: row;

  .rhap_controls-section {
    margin-left: 8px;
  }
}

.rhap_horizontal-reverse {
  flex-direction: row-reverse;

  .rhap_controls-section {
    margin-right: 8px;
  }
}

.rhap_stacked-reverse {
  flex-direction: column-reverse;

  .rhap_controls-section {
    margin-bottom: 8px;
  }
}

.rhap_progress-section {
  display: flex;
  flex: 3 1 auto;
  align-items: center;
}

.rhap_progress-container {
  display: flex;
  align-items: center;
  height: 20px;
  flex: 1 0 auto;
  align-self: center;
  margin: 0 calc(10px + 1%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_time {
  color: $rhap_time-color;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
}

.rhap_progress-bar {
  box-sizing: border-box;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 5px;
  background-color: $rhap_bar-color;
  border-radius: 2px;
}

.rhap_progress-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  background-color: $rhap_theme-color;
  border-radius: 2px;
}

.rhap_progress-bar-show-download {
  background-color: rgba($rhap_bar-color, 0.5);
}

.rhap_download-progress {
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: $rhap_bar-color;
  border-radius: 2px;
}

.rhap_progress-indicator {
  box-sizing: border-box;
  position: absolute;
  z-index: 3;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  top: -8px;
  background: $rhap_theme-color;
  border-radius: 50px;
  box-shadow: rgba($rhap_theme-color, .5) 0 0 5px;
}

.rhap_controls-section {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
}

.rhap_additional-controls {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  background-color: $altBlack;
}

.rhap_repeat-button {
  font-size: 26px;
  width: 26px;
  height: 26px;
  margin-right: 6px;
  border: 1px solid green;
}

.rhap_main-controls {
  flex: 0 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rhap_main-controls-button {
  margin: 0 3px;
  color: $rhap_theme-color;
  font-size: 35px;
  width: 35px;
  height: 35px;
}

.rhap_play-pause-button {
  font-size: 40px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.rhap_volume-controls {
  display: flex;
  flex: 1 0 auto;
  justify-content: flex-end;
  align-items: center;
  background-color: $altBlack;
}

.rhap_volume-button {
  flex: 0 0 26px;
  font-size: 26px;
  width: 26px;
  height: 26px;
  color: $pureWhite;
  margin-right: 6px;
}

.rhap_volume-container {
  display: flex;
  align-items: center;
  flex: 0 1 100px;
  user-select: none;
  -webkit-user-select: none;
  background-color: $altBlack;
}

.rhap_volume-bar-area {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14px;
  cursor: pointer;
  background-color: $altBlack;

  &:focus:not(:focus-visible) {
    outline: 0;
  }
}

.rhap_volume-bar {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 4px;
  background: $pureWhite;
  border-radius: 2px;
}

.rhap_volume-indicator {
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 12px;
  margin-left: -6px;
  left: 0;
  top: -4px;
  opacity: 0.9;
  border-radius: 50px;
  cursor: pointer;
  background-color: $pureWhite;

  &:hover {
    opacity: .9;
  }
}

.rhap_volume-filled {
  height: 100%;
  position: absolute;
  z-index: 2;
  border-radius: 2px;
  background-color: $pureWhite;
}

/* Utils */
.rhap_button-clear {
  background-color: transparent;
  border: none;
  padding: 0;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    opacity: .9;
    transition-duration: .2s;
    cursor: pointer;
  }

  &:active {
    opacity: .95;
    cursor: pointer;
  }

  &:focus:not(:focus-visible) {
    outline: 0;
    cursor: pointer;
  }
}