@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$swipoGreen: #10D48E;
$pureWhite: #FFFFFF;
$richBlack: #0E0F0E;
$altBlack: rgba(22,22,22,1);
$borderFaint: #DDDDDD;

$input-font-size: 25px;

#genrePickerContainer {
    // border: 3px solid red;
    display: flex; 
    position: relative;

    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: fit-content;
    margin-top: 105px;
    overflow: auto;
    z-index: 1;
    #genreHeader {
        font-size: 40px;
        font-weight: 500;
        margin-top: 0;
        @media (max-width: 475px) {
            font-size: 25px;
        }
    }
    #searchBarContainer {
        display: flex;
        // border: 1px solid $swipoGreen;
        width: 50%;
        @media (max-width: 768px) {
            width: 80%;
        }
        height: 50px;
        input {
            z-index: 2;
            width: 90%;
            outline: none;
            border: 1px solid $borderFaint;
            border-radius: 20px 0px 0px 20px;
            font-size: $input-font-size;
            padding-left: 20px;
            @media (max-width: 475px){
                width: 85%;
                font-size: 18px;
            }
            // &::placeholder {
            //     font-size: $input-font-size;
            // }
        }
        button {
            z-index: 1;
            width: 10%;
            padding: 5px;
            @media (max-width: 475px) {
                width: 15%;
            }
            border: 1px solid $borderFaint;
            border-radius: 0px 20px 20px 0px;
            margin-left: -4px;
            background-color: white;
            img {
                margin: 0 auto;
                width: 80%;
                max-width: 40px;
            }
            &:hover {
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.473);
            }
        }
    }

    #boxPlaylistTypeSelector {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        // border: 1px solid red;
        label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            p {
                margin-top: 20px;
            }
        }
    }
}

//PLAYLISTGRID
#playlistGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 200px;
    // border: 3px solid black;
    width: clamp(200px, 95%, 900px);
}
.playlistGridItem button{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid $borderFaint;
    border-radius: 20px;
    background-color: white;
    aspect-ratio: 6/7;
    padding: 15px 10px 5px 10px;
    img {
        // border-radius: 15px;
        height: 150px;
        width: 150px;
        margin-bottom: 10px;
        pointer-events: none;
    }
    p {
        max-width: 150px;
        // max-height: 14px;
        color: $altBlack;
        margin: 0;
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none; 
        &#playlistOwner {
            font-size: 10px;
            color: rgba(22, 22, 22, 0.616);
            margin: 3px 0px 5px 0px;
        }
    }
}


