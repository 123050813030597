$swipoGreen: #10D48E;
$pureWhite: #FFFFFF;
$richBlack: #0E0F0E;
$altBlack: rgba(22,22,22,1);
$offWhite: #F0F0F0;

.top-nav {
// general
    background-color: $offWhite;
    width: 100vw;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 2;
    // border: 1px solid black;
    #swipo-logo {
        width: 15vw;
        min-width: 150px;
        margin-left: 60px;
        @media (max-width: 425px) {
            margin-left: 30px;
        }
        // border: 1px solid green;
    }
    #profile {
        // border: 1px solid green;
        height: 40px;
        width: 40px;
        margin-right: 20px;
        border-radius: 100px;
        
    }
    #sidebarButton {
        border: none;
        background-color: transparent;
        cursor: pointer;
        img {
            // border: 1px solid green;
            width: 40px;
            height: 40px;
            margin-right: 60px;
            @media (max-width: 425px) {
                margin-right: 30px;
            }
        }
        #sidebarButtonBurger {
            width: 100%;
            height: 3px;
            background: black;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(255,101,47,.2);
            transition: all .5s ease-in-out;
            cursor: pointer;
        }
    }
    #sideBarWrapper {
        display: flex;
        position: absolute;
        z-index: 1;
        top: 0;
        right: -100%;
        width: 100vw;
        height: 100vh;
        transition: all 0.40s ease-in-out;
        #transparentClickSpace {
            position: relative;
            z-index: 2;
            width: 50%;
            @media (max-width: 425px) {
                width: 0;
            }
            height: 100vh;
            background-color: rgba(0, 0, 0, 0.315);
        }
        #sideBar {
            position: relative;
            top: 0;
            z-index: 2;
            width: 50%;
            min-width: 300px;

            padding: 50px;
            @media (max-width: 425px) {
                min-width: 0;
                width: 100vw;
                padding: 35px;
            }
            height: 100vh;
            background-color: #F0F0F0;
            transition: all 0.45s ease-in-out;
            #sideBarLinks {
                // border: 1px solid black;
                height: 100%;
                h1 {
                    color: $altBlack;
                    font-weight: 500;
                }
                img {
                    width: 40px;
                    height: 40px;
                }
                a {
                    color: $altBlack;
                    text-decoration: none;
                }
                .faintText{
                    color:rgba(22, 22, 22, 0.288);
                }
                #settingsBox {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 40%;
                    border: 1px solid rgba(22,22,22,1);
                    border-radius: 20px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}